import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../../../components/Layout/Layout';
import Seo from '../../../components/Seo/Seo';
import useIcons from '../../../hooks/useIcons';
import CollarLogo from '../../../images/collarLogo.svg';
import { clearLocalStorage } from '../../../utils';
import AnalyticsHeaders from './AnalyticsHeaders';
import { BackImage, BookButton, BranchAddress, BranchBox, BranchContainer, BranchLogo, LogoContainer } from './styled';

function BranchView({ pageContext }) {
  const { branch } = pageContext;
  const { address, name, photos, colors } = branch;
  const icons = useIcons();
  const backIcon = getImage(icons?.arrowLeft)!;
  return (
    <Layout>
      <BackImage to="../" onClick={() => clearLocalStorage()}>
        <GatsbyImage image={backIcon} alt={'Back Icon'} />
      </BackImage>
      <BranchContainer primaryColor={colors.primary}>
        <BranchBox>
          <BranchLogo src={photos.logo} alt={name} />
          <BranchAddress>
            {address.street} {address.city} {address.postcode}{' '}
          </BranchAddress>
          <BookButton to="book" onClick={() => clearLocalStorage()}>
            Book Services
          </BookButton>
          <LogoContainer>
            <Link to="https://collar.pet/download" target="_blank" rel="noopener noreferrer">
              <CollarLogo />
            </Link>
          </LogoContainer>
        </BranchBox>
      </BranchContainer>
    </Layout>
  );
}

export default BranchView;

export const Head = ({ pageContext, data }) => {
  const { branch } = pageContext;
  const { address, name, photos, colors, analytics } = branch;
  return (
    <Seo
      title={name}
      description={`Book services from ${name}, manage your pets data and connect with pet owners and businesses all while staying on top of your pets health and wellness.`}
      image={photos.logo}
    >
      <AnalyticsHeaders analytics={analytics} />
    </Seo>
  );
};
