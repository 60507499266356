import * as React from 'react';

function AnalyticsHeaders({ analytics }: { analytics: Queries.CollarAPI_Branch['analytics'] }) {
  return (
    <>
      {analytics?.google?.analytics_id && (
        <>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${analytics.google.analytics_id}`} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.branch_data_layer = window.branch_data_layer || [];
            function gtag(){branch_data_layer.push(arguments)}
            gtag('js', new Date());
            gtag('config', '${analytics.google.analytics_id}', { send_page_view: false });
          `
            }}
          />
        </>
      )}
      {analytics?.google?.tag_manager_id && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&amp;l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','branch_data_layer','${analytics.google.tag_manager_id}');
          `
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${analytics.google.tag_manager_id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `
            }}
          />
        </>
      )}
      {analytics?.facebook?.pixel_id && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            branch_data_layer.push('track', 'PageView');
            fbq('init', '${analytics.facebook.pixel_id}');
            fbq('track', 'PageView');
          `
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${analytics.facebook.pixel_id}&amp;ev=PageView&amp;noscript=1"
            />
          `
            }}
          />
        </>
      )}
    </>
  );
}

export default AnalyticsHeaders;
