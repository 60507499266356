import { Link } from 'gatsby';
import styled from 'styled-components';

export const BranchContainer = styled.div<{ primaryColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ primaryColor }) => primaryColor};
  padding: 20px;
`;

export const BranchBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 541px;
  height: 577px;
  border-radius: 28px;
  background-color: var(--white);
  gap: 28px;
  text-align: center;
  padding: 20px;
`;

export const BranchLogo = styled.img`
  width: 120px;
  margin-top: 56px;
  user-select: none;
`;

export const BranchAddress = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
`;

export const BookButton = styled(Link)`
  padding: 12px 32px;
  border-radius: 22.5px;
  background-color: var(--black);
  color: var(--white);
  font-size: 16px;
  margin-top: 173px;
  user-select: none;
`;

export const LogoContainer = styled.div`
  margin-top: auto;
  margin-bottom: 24px;
  user-select: none;
`;

export const BackImage = styled(Link)`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
`;
